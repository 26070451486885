import { createMuiTheme }  from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        primary: { 500: '#467fcf' },
        common: {
            gray: '#688999',
        },
    },
    typography: {
        fontFamily: '"Noto Sans TC", "Roboto", "Helvetica", "Arial", sans-serif',
        body2: {
            fontSize: '1rem',
        },
    },
    container: {
        width: '90%',
        margin: '2rem auto',
        boxSizing: 'border-box',
        '@media (min-width: 768px)': {
            width: '80%',
            maxWidth: '1200px',
        }
    },
})

export default theme