import React from 'react';
import styled, { keyframes } from 'styled-components';

import Backdrop from './Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const Spinner = (props) => {
    return (
        <React.Fragment>
        <Backdrop show={ props.show_backdrop } />
        <Container>
            { props.show_backdrop 
            ? <React.Fragment><CircularProgress style={{ color:'#FFFFFF' }} /> <Text style={{ color:'#FFFFFF' }}>{props.message ? props.message : '處理中'}...</Text></React.Fragment>
            : <React.Fragment><CircularProgress color='secondary' /> <Text>{props.message ? props.message : '處理中'}...</Text></React.Fragment> }            
        </Container>
        </React.Fragment>
    );
};

export default Spinner;

const Container = styled.div`
    margin-top: 20vh;
    text-align: center;
    // this is so it's above mui's modal z-index 1300
    z-index: 1302;
    position: fixed;
    left: 41%;
`;

const Text = styled.span`
    display: inline-block;
    color: var(--kpvsBrown);
    position: relative;
    font-size: 1.2rem;
    vertical-align: top;
    padding-top: 0.5rem;
    margin-left: 1rem;
`;