import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    sem1_list_page: 0,
    sem1_list_rows_per_page: 20,
    sem1_list_class_year: ' ',
    sem1_list_query: '',
    sem1_list_sem1_time: ' ',
    sem1_list_curr_acad_year: ' ',
    sem1_list_dept: ' ',
    sem1_list_order_by: 'id_admission DESC',

    list_page: 0,
    list_rows_per_page: 20,
    list_class_year: ' ',
    list_query: '',
    list_sem1_time: ' ',
    list_curr_acad_year: ' ',
    list_dept: ' ',
    list_order_by: 'id_admission DESC',
};

const set_adm_sem1_list_page = (state, action) => {
    return updateObject(state, {
        sem1_list_page: action.data,
    });
};

const set_adm_sem1_list_rows_per_page = (state, action) => {
    return updateObject(state, {
        sem1_list_rows_per_page: action.data,
    });
};

const set_adm_sem1_list_class_year = (state, action) => {
    return updateObject(state, {
        sem1_list_class_year: action.data,
    });
};

const set_adm_sem1_list_query = (state, action) => {
    return updateObject(state, {
        sem1_list_query: action.data,
    });
};

const set_adm_sem1_list_sem1_time = (state, action) => {
    return updateObject(state, {
        sem1_list_sem1_time: action.data,
    });
};

const set_adm_sem1_list_curr_acad_year = (state, action) => {
    return updateObject(state, {
        sem1_list_curr_acad_year: action.data,
    });
};

const set_adm_sem1_list_dept = (state, action) => {
    return updateObject(state, {
        sem1_list_dept: action.data,
    });
};

const set_adm_sem1_list_order_by = (state, action) => {
    return updateObject(state, {
        sem1_list_order_by: action.data,
    });
};

const set_adm_list_page = (state, action) => {
    return updateObject(state, {
        list_page: action.data,
    });
};

const set_adm_list_rows_per_page = (state, action) => {
    return updateObject(state, {
        list_rows_per_page: action.data,
    });
};

const set_adm_list_class_year = (state, action) => {
    return updateObject(state, {
        list_class_year: action.data,
    });
};

const set_adm_list_query = (state, action) => {
    return updateObject(state, {
        list_query: action.data,
    });
};

const set_adm_list_sem1_time = (state, action) => {
    return updateObject(state, {
        list_sem1_time: action.data,
    });
};

const set_adm_list_curr_acad_year = (state, action) => {
    return updateObject(state, {
        list_curr_acad_year: action.data,
    });
};

const set_adm_list_dept = (state, action) => {
    return updateObject(state, {
        list_dept: action.data,
    });
};

const set_adm_list_order_by = (state, action) => {
    return updateObject(state, {
        list_order_by: action.data,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ADM_SEM1_LIST_PAGE: return set_adm_sem1_list_page(state, action);
        case actionTypes.SET_ADM_SEM1_LIST_ROWS_PER_PAGE: return set_adm_sem1_list_rows_per_page(state, action);
        case actionTypes.SET_ADM_SEM1_LIST_CLASS_YEAR: return set_adm_sem1_list_class_year(state, action);
        case actionTypes.SET_ADM_SEM1_LIST_QUERY: return set_adm_sem1_list_query(state, action);
        case actionTypes.SET_ADM_SEM1_LIST_SEM1_TIME: return set_adm_sem1_list_sem1_time(state, action);
        case actionTypes.SET_ADM_SEM1_LIST_CURR_ACAD_YEAR: return set_adm_sem1_list_curr_acad_year(state, action);
        case actionTypes.SET_ADM_SEM1_LIST_DEPT: return set_adm_sem1_list_dept(state, action);
        case actionTypes.SET_ADM_SEM1_LIST_ORDER_BY: return set_adm_sem1_list_order_by(state, action);

        case actionTypes.SET_ADM_LIST_PAGE: return set_adm_list_page(state, action);
        case actionTypes.SET_ADM_LIST_ROWS_PER_PAGE: return set_adm_list_rows_per_page(state, action);
        case actionTypes.SET_ADM_LIST_CLASS_YEAR: return set_adm_list_class_year(state, action);
        case actionTypes.SET_ADM_LIST_QUERY: return set_adm_list_query(state, action);
        case actionTypes.SET_ADM_LIST_SEM1_TIME: return set_adm_list_sem1_time(state, action);
        case actionTypes.SET_ADM_LIST_CURR_ACAD_YEAR: return set_adm_list_curr_acad_year(state, action);
        case actionTypes.SET_ADM_LIST_DEPT: return set_adm_list_dept(state, action);
        case actionTypes.SET_ADM_LIST_ORDER_BY: return set_adm_list_order_by(state, action);
        default: return state;
    };
};

export default reducer;