import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    footer_container: {
        backgroundColor: theme.palette.background.default,
    },
}));

const Footer = (props) => {
    const [state_show, set_show] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        switch (window.location.pathname) {
            case '/login':
            case '/logout':
                set_show(false);
            break;
            default:
                set_show(true);
        }    
    });

    let now = new Date();
    let yearCurrent = now.getFullYear();

    let contents = null;
    if (state_show) {
        contents = (
            <Container className={classes.footer_container}>
            <hr style={{ marginBottom:'0', marginTop:'0' }} />
            <div style={{ fontSize: '0.8rem', width: '98%', margin: '0 auto' }}>
                <span>&copy;1953-{ yearCurrent } 開平餐飲學校</span>
                <span style={{ float: 'right' }}>Powered by HARDCORP</span>
            </div>
            </Container>
        );
    }

    return contents;
};

export default Footer;

const Container = styled.div`
    position: fixed;
    z-index: 99;  // backdrop is 100
    left: 0;
    bottom: 0;
    width: 100%;
`;