import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    kpce_student_list_query_page: 0,
    kpce_student_list_query_rows_per_page: 20,
    kpce_student_list_query_query: '',
    kpce_student_list_query_order_by: 'id_kpce DESC',
    kpce_course_list_query_page: 0,
    kpce_course_list_query_rows_per_page: 20,
    kpce_course_list_query_query: {
        year: ' ',
        quarter: 0,
        query: '',
    },
    kpce_course_list_query_order_by: 'year DESC, quarter DESC, id_course ASC',
    kpce_registration_list_query_page: 0,
    kpce_registration_list_query_rows_per_page: 20,
    kpce_registration_list_query_query: {
        year: '',
        quarter: 0,
        query: '',
    },
    kpce_registration_list_query_order_by: 'id_course ASC',
    kpce_teacher_list_query_page: 0,
    kpce_teacher_list_query_rows_per_page: 20,
    kpce_teacher_list_query_query: '',
    kpce_teacher_list_query_order_by: 'name_full_zh ASC',
    kpce_fee_list_query_page: 0,
    kpce_fee_list_query_rows_per_page: 20,
    kpce_fee_list_query_query: {
        year: '',
        quarter: 0,
        query: '',
    },
    kpce_fee_list_query_order_by: 'title_zh ASC',     // this is ignored because core get_fee_list has its own order by
};

const set_kpce_student_list_query_page = (state, action) => {
    return updateObject(state, {
        kpce_student_list_query_page: action.data,
    });
};

const set_kpce_student_list_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        kpce_student_list_query_rows_per_page: action.data,
    });
};

const set_kpce_student_list_query_query = (state, action) => {
    return updateObject(state, {
        kpce_student_list_query_query: action.data,
    });
};

const set_kpce_student_list_query_order_by = (state, action) => {
    return updateObject(state, {
        kpce_student_list_query_order_by: action.data,
    });
};

const set_kpce_course_list_query_page = (state, action) => {
    return updateObject(state, {
        kpce_course_list_query_page: action.data,
    });
};

const set_kpce_course_list_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        kpce_course_list_query_rows_per_page: action.data,
    });
};

const set_kpce_course_list_query_query = (state, action) => {
    return updateObject(state, {
        kpce_course_list_query_query: action.data,
    });
};

const set_kpce_course_list_query_order_by = (state, action) => {
    return updateObject(state, {
        kpce_course_list_query_order_by: action.data,
    });
};

const set_kpce_registration_list_query_page = (state, action) => {
    return updateObject(state, {
        kpce_registration_list_query_page: action.data,
    });
};

const set_kpce_registration_list_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        kpce_registration_list_query_rows_per_page: action.data,
    });
};

const set_kpce_registration_list_query_query = (state, action) => {
    return updateObject(state, {
        kpce_registration_list_query_query: action.data,
    });
};

const set_kpce_registration_list_query_order_by = (state, action) => {
    return updateObject(state, {
        kpce_registration_list_query_order_by: action.data,
    });
};

const set_kpce_teacher_list_query_page = (state, action) => {
    return updateObject(state, {
        kpce_teacher_list_query_page: action.data,
    });
};

const set_kpce_teacher_list_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        kpce_teacher_list_query_rows_per_page: action.data,
    });
};

const set_kpce_teacher_list_query_query = (state, action) => {
    return updateObject(state, {
        kpce_teacher_list_query_query: action.data,
    });
};

const set_kpce_teacher_list_query_order_by = (state, action) => {
    return updateObject(state, {
        kpce_teacher_list_query_order_by: action.data,
    });
};

const set_kpce_fee_list_query_page = (state, action) => {
    return updateObject(state, {
        kpce_fee_list_query_page: action.data,
    });
};

const set_kpce_fee_list_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        kpce_fee_list_query_rows_per_page: action.data,
    });
};

const set_kpce_fee_list_query_query = (state, action) => {
    return updateObject(state, {
        kpce_fee_list_query_query: action.data,
    });
};

const set_kpce_fee_list_query_order_by = (state, action) => {
    return updateObject(state, {
        kpce_fee_list_query_order_by: action.data,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_KPCE_STUDENT_LIST_QUERY_PAGE: return set_kpce_student_list_query_page(state, action);
        case actionTypes.SET_KPCE_STUDENT_LIST_QUERY_ROWS_PER_PAGE: return set_kpce_student_list_query_rows_per_page(state, action);
        case actionTypes.SET_KPCE_STUDENT_LIST_QUERY_QUERY: return set_kpce_student_list_query_query(state, action);
        case actionTypes.SET_KPCE_STUDENT_LIST_QUERY_ORDER_BY: return set_kpce_student_list_query_order_by(state, action);
        case actionTypes.SET_KPCE_COURSE_LIST_QUERY_PAGE: return set_kpce_course_list_query_page(state, action);
        case actionTypes.SET_KPCE_COURSE_LIST_QUERY_ROWS_PER_PAGE: return set_kpce_course_list_query_rows_per_page(state, action);
        case actionTypes.SET_KPCE_COURSE_LIST_QUERY_QUERY: return set_kpce_course_list_query_query(state, action);
        case actionTypes.SET_KPCE_COURSE_LIST_QUERY_ORDER_BY: return set_kpce_course_list_query_order_by(state, action);
        case actionTypes.SET_KPCE_REGISTRATION_LIST_QUERY_PAGE: return set_kpce_registration_list_query_page(state, action);
        case actionTypes.SET_KPCE_REGISTRATION_LIST_QUERY_ROWS_PER_PAGE: return set_kpce_registration_list_query_rows_per_page(state, action);
        case actionTypes.SET_KPCE_REGISTRATION_LIST_QUERY_QUERY: return set_kpce_registration_list_query_query(state, action);
        case actionTypes.SET_KPCE_REGISTRATION_LIST_QUERY_ORDER_BY: return set_kpce_registration_list_query_order_by(state, action);
        case actionTypes.SET_KPCE_TEACHER_LIST_QUERY_PAGE: return set_kpce_teacher_list_query_page(state, action);
        case actionTypes.SET_KPCE_TEACHER_LIST_QUERY_ROWS_PER_PAGE: return set_kpce_teacher_list_query_rows_per_page(state, action);
        case actionTypes.SET_KPCE_TEACHER_LIST_QUERY_QUERY: return set_kpce_teacher_list_query_query(state, action);
        case actionTypes.SET_KPCE_TEACHER_LIST_QUERY_ORDER_BY: return set_kpce_teacher_list_query_order_by(state, action);
        case actionTypes.SET_KPCE_FEE_LIST_QUERY_PAGE: return set_kpce_fee_list_query_page(state, action);
        case actionTypes.SET_KPCE_FEE_LIST_QUERY_ROWS_PER_PAGE: return set_kpce_fee_list_query_rows_per_page(state, action);
        case actionTypes.SET_KPCE_FEE_LIST_QUERY_QUERY: return set_kpce_fee_list_query_query(state, action);
        case actionTypes.SET_KPCE_FEE_LIST_QUERY_ORDER_BY: return set_kpce_fee_list_query_order_by(state, action);

        default: return state;
    };
};

export default reducer;