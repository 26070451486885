import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    guardian_event_list_page: 0,
    guardian_event_list_rows_per_page: 20,
    guardian_event_list_query: '',

    guardian_event_result_query: '',
    guardian_event_result_nianban: new Date().getFullYear() - 1911 + 3,
    guardian_event_result_dept: 2,
    guardian_event_result_mins: 0,

    guardian_event_export_page: 0,
    guardian_event_export_rows_per_page: 20,
    guardian_event_export_query: '',
};

const set_guardian_event_list_page = (state, action) => {
    return updateObject(state, {
        guardian_event_list_page: action.data,
    });
};

const set_guardian_event_list_rows_per_page = (state, action) => {
    return updateObject(state, {
        guardian_event_list_rows_per_page: action.data,
    });
};

const set_guardian_event_list_query = (state, action) => {
    return updateObject(state, {
        guardian_event_list_query: action.data,
    });
};

// ==========

const set_guardian_event_result_query = (state, action) => {
    return updateObject(state, {
        guardian_event_result_query: action.data,
    });
};

const set_guardian_event_result_nianban = (state, action) => {
    return updateObject(state, {
        guardian_event_result_nianban: action.data,
    });
};

const set_guardian_event_result_dept = (state, action) => {
    return updateObject(state, {
        guardian_event_result_dept: action.data,
    });
};

const set_guardian_event_result_mins = (state, action) => {
    return updateObject(state, {
        guardian_event_result_mins: action.data,
    });
};

// ==========

const set_guardian_event_export_page = (state, action) => {
    return updateObject(state, {
        guardian_event_export_page: action.data,
    });
};

const set_guardian_event_export_rows_per_page = (state, action) => {
    return updateObject(state, {
        guardian_event_export_rows_per_page: action.data,
    });
};

const set_guardian_event_export_query = (state, action) => {
    return updateObject(state, {
        guardian_event_export_query: action.data,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_GUARDIAN_EVENT_LIST_PAGE: return set_guardian_event_list_page(state, action);
        case actionTypes.SET_GUARDIAN_EVENT_LIST_ROWS_PER_PAGE: return set_guardian_event_list_rows_per_page(state, action);
        case actionTypes.SET_GUARDIAN_EVENT_LIST_QUERY: return set_guardian_event_list_query(state, action);

        case actionTypes.SET_GUARDIAN_EVENT_RESULT_QUERY: return set_guardian_event_result_query(state, action);
        case actionTypes.SET_GUARDIAN_EVENT_RESULT_NIANBAN: return set_guardian_event_result_nianban(state, action);
        case actionTypes.SET_GUARDIAN_EVENT_RESULT_DEPT: return set_guardian_event_result_dept(state, action);
        case actionTypes.SET_GUARDIAN_EVENT_RESULT_MINS: return set_guardian_event_result_mins(state, action);

        case actionTypes.SET_GUARDIAN_EVENT_EXPORT_PAGE: return set_guardian_event_export_page(state, action);
        case actionTypes.SET_GUARDIAN_EVENT_EXPORT_ROWS_PER_PAGE: return set_guardian_event_export_rows_per_page(state, action);
        case actionTypes.SET_GUARDIAN_EVENT_EXPORT_QUERY: return set_guardian_event_export_query(state, action);

        default: return state;
    };
};

export default reducer;